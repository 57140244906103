<template>
<div class="product pt-100">
   
        <router-view>
            <div class="">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="all"
                           value="option2" checked @click="getAllProducts()">
                    <label class="form-check-label capitalize" for="all" @click="getAllProducts()">All</label>
                </div>
                <div class="form-check form-check-inline" v-for="category in categories" :key="category.id">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" :id="category" value=""
                           @click="getProducts(category)">
                    <label class="form-check-label capitalize" :for="category" @click="getProducts(category)">{{category}}</label>
                </div>
            </div>

            <transition name="fadeIn">
                <div class="container" v-if="postsVisibiblity" v-cloak>
                    <h2 class="text-center my-3">Products</h2>
                    <div class="input-group mx-auto search mb-30">
                        <input id="key-words" type="text" class="form-control" placeholder="Enter key words"
                               aria-label="Enter key words" aria-describedby="button-search" v-model="data">
                        <button class="btn btn-outline-secondary" type="button" id="button-search" @click="getSearch(data)">Search</button>
                    </div>

                    <div class="products" v-for="product in products" :key="product.id">

                        <div class="row gray-bg">
                            <div class="col-md-1 col-2 alert alert-primary">
                                <span>№ {{product.id}} </span>

                            </div>

                            <div class="col-md-2 col-5 text-capitalize alert alert-primary">
                                <img :src="product.image" alt="" height="100" width="100">
                            </div>
                            <div class="col-md-1 col-5 text-capitalize alert alert-primary ">
                                <span class="badge bg-info text-dark"
                                      v-if="product.price < 100">$ {{product.price}}</span>
                                <span class="badge bg-danger text-white" v-else>$ {{product.price}}</span>
                            </div>
                            <p class="col-md-2 col-12 text-capitalize alert alert-primary">{{product.category}}</p>
                            <div class="col-md-6 col-12 text-capitalize alert alert-primary">
                                <h5 class="pb-3">{{product.title}}</h5>
                                {{product.description}}
                            </div>
                            <!--                        <div class="col-6 alert alert-secondary">{{post.body}}</div>-->
                        </div>
                    </div>
                </div>

            </transition>
        </router-view>
    </div>
</template>

<script>
    import Nav from "@/components/Nav.vue";

    import axios from "axios";
    const api_key = process.env.VUE_APP_API_KEY;
    console.log(api_key);
    export default {
        name: "Products",
        components: {
            Nav
        },
        data() {
            return {
                products: [],
                posts: [],
                categories: [],
                comments: [],
                post_code:[],
                postsVisibiblity: false,
                src: '',
                words:'',
                textColors: ['success', 'danger', 'warning'],
                limit: 100
            }
        },
        methods: {
            async getProducts(category) {

                document.querySelector('.preloader').style.display = 'block';
                axios.defaults.headers.get['Content-Type'] = 'application/json';
                axios.defaults.headers.get['Header-X-Api-Key'] = api_key;
                await axios
                // .get("http://192.168.1.10:8000/api/v2/products")
                //     .get("https://jsonplaceholder.typicode.com/posts/")
                    .get("https://fakestoreapi.com/products/category/" + category)
                    .then((response) => {
                        this.products = response.data;
                        document.querySelector('.preloader').style.display = 'none';
                    })
                    .catch(function (error) {
                        console.log(error.response);
                        console.log(error.response.status);
                    });

            },
            async getAllProducts(limit) {
                document.querySelector('.preloader').style.display = 'block';
                axios.defaults.headers.get['Content-Type'] = 'application/json';
                axios.defaults.headers.get['Header-X-Api-Key'] = api_key;
                await axios
                // .get("http://192.168.1.10:8000/api/v2/products")
                //     .get("https://jsonplaceholder.typicode.com/posts/")
                    .get("https://fakestoreapi.com/products?limit=" + this.limit)
                    .then((response) => {
                        this.products = response.data;
                        document.querySelector('.preloader').style.display = 'none';
                    })
                    .catch(function (error) {
                        console.log(error.response);
                        console.log(error.response.status);
                    });

            },
            async getCategories() {

                axios.defaults.headers.get['Content-Type'] = 'application/json';
                axios.defaults.headers.get['Header-X-Api-Key'] = api_key;
                await axios
                // .get("http://192.168.1.10:8000/api/v2/products")
                //     .get("https://jsonplaceholder.typicode.com/posts/")
                    .get("https://fakestoreapi.com/products/categories")
                    .then((response) => {
                        this.categories = response.data;
                    })
                    .catch(function (error) {
                        console.log(error.response);
                        console.log(error.response.status);
                    });

            },
            async getComments() {
                axios.defaults.headers.get['Content-Type'] = 'application/json';
                axios.defaults.headers.get['Header-X-Api-Key'] = api_key;
                await axios
                // .get("http://192.168.1.10:8000/api/v2/products")
                    .get("https://jsonplaceholder.typicode.com/posts/1/comments")
                    .then((response) => {
                        this.comments = response.data;
                    })
            },

            async getSearch(words) {
                axios.defaults.headers.get['Content-Type'] = 'application/json';
                axios.defaults.headers.get['Header-X-Api-Key'] = api_key;
                await axios
                // .get("http://192.168.1.10:8000/api/v2/products")
                    .get("http://localhost:8085/api/v2/ukrpost/search=" + words)
                    .then((response) => {
                        this.post_code = response.data;
                        console.log(this.post_code);
                    })
            },

        },
        async mounted() {

            this.getCategories();
            this.getAllProducts(10);
            this.postsVisibiblity = true
            document.querySelector('.product').style.minHeight = window.innerHeight - 60 + 'px'
        },
        computed() {
            words = document.getElementById('key-words');
            // document.querySelector('.product').style.minheight = window.innerHeight + 'px'
        }
    }

</script>

<style scoped>
    .container {
        max-width: 1024px;
    }

    .product {
        margin-top: 20px;
    }

    .search {
        max-width: 500px;
    }

    .mb-30 {
        margin-bottom: 30px;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .form-check-input, .form-check-label:hover {
        cursor: pointer;
    }

    [v-cloak] {
        display: none;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .fadeIn-enter-active,
    .fadeIn-leave-active {
        transition: opacity 0.4s ease;
    }

    .fadeIn-enter-from,
    .fadeIn-leave-to {
        opacity: 0;
    }

    p::first-letter {
        font-size: 2em; /* Размер буквы */
        color: blue; /* Цвет буквы */
        float: left; /* Выравнивание по левому краю */
        margin: 0 4px 2px 0; /* Отступы вокруг буквы */
    }
</style>
